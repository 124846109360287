import Layout from 'components/content/Layout';


function NotFoundPage() {
  return <Layout>
    <div className="text-center">
      <h2>Page not found or insufficient privileges</h2>
      <div><img src="/static/img/page-not-found.svg" alt="Page not found" /></div>
    </div>
  </Layout>
}

export default NotFoundPage;
